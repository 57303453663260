<template lang="pug">
  .home
    .home-logo
      .home-center.home-icon
        img#home-img.home-img(src="../../img/logo_img.png", width=75, height=100 alt="")
      .home-center.home-logo-img
        include ../partials/logo2.pug

    ul#home-nav.home-nav
      li: router-link(to="gaitou"): img(src="../../img/home_gaito.png", width=90, height=115, alt="")
      li: router-link(to="jiko"): img(src="../../img/home_jiko.png", width=90, height=115, alt="")
</template>

<style lang="stylus" scoped>
  .home
    &-icon
      height: 100px
    &-logo
      padding-top: 100px
      &-img
        padding-top: 20px
    &-center
      text-align: center
    &-img
      opacity: 0

    &-nav
      list-style: none
      padding: 40px 0 0
      text-align: center
      opacity: 0
      width: 60%
      margin: 0 auto
      display: flex
      flex-flow: row nowrap
      justify-content: space-around
      align-items: center

      li
        background: #eeeeee
</style>

<script>
  import animejs from 'animejs'

  export default {
    // metaInfo: {
    //   title: 'bar'
    // },
    data () {
      return {}
    },
    computed: {},
    created () {
      this.$nextTick(() => {
        this.logoText()
      })
    },
    methods: {
      logoText () {
        animejs({
          targets: '#logos .logos-g',
          opacity: [0, 1],
          delay: (el, i, l) => i * 200,
          complete: () => {
            this.logoImage()
          }
        })
      },

      logoImage () {
        animejs({
          targets: '#home-img',
          opacity: [0, 1],
          scaleY: [1.3, 1],
          duration: 500,
          complete: () => {
            this.showNav()
          }
        })
      },

      showNav () {
        animejs({
          targets: '#home-nav',
          opacity: 1,
          duration: 400,
          easing: 'linear'
        })
      }
    }
  }
</script>