import router from './modules/router'
import store from './modules/store'
import { title } from '../../config/default.yml'

// イベントエミッター
Vue.prototype.$bus = new Vue()

const apps = new Vue({
  router,
  store,
  // @see: https://github.com/declandewet/vue-meta
  metaInfo: {
    titleTemplate: (chunk) => {
      return chunk ? `${chunk} | ${title}` : title
    }
  }
})
apps.$mount('#apps')