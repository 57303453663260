<template lang="pug">
  nav.nav
    ul.list
      li: router-link(to="home"): img(src="../../img/menu_home.png", alt="", width=60, height=40)
      li: router-link(to="gaitou"): img(src="../../img/menu_gaito.png", alt="", width=60, height=40)
      li: router-link(to="jiko"): img(src="../../img/menu_jiko.png", alt="", width=60, height=40)

</template>

<style lang="stylus" scoped>
.nav
  background: #fff

.list
  margin: 0
  padding: 0
  list-style: none
  display: flex
  flex-flow: row nowrap
  justify-content: space-around
  align-items: top
  height: 100%
  padding-top: 20px
</style>

<script>
  export default {
    // metaInfo: {
    //   title: 'bar'
    // },
    data () {
      return {}
    },
    computed: {
    }
  }
</script>