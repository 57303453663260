<template lang="pug">
  .jiko
    #map.map

    .jiko-time
      button.btn.morning(type="button", @click="switchHeatMap(0)", @touchstart.stop="scaleOut($event)", @touchend.stop="scaleIn($event)"): img(src="../../img/icon_morning.svg", width=128, height=128, alt="")
      button.btn.evening(type="button", @click="switchHeatMap(1)", @touchstart.stop="scaleOut($event)", @touchend.stop="scaleIn($event)"): img(src="../../img/icon_evening.svg", width=128, height=128, alt="")
      button.btn.night(type="button", @click="switchHeatMap(2)", @touchstart.stop="scaleOut($event)", @touchend.stop="scaleIn($event)"): img(src="../../img/icon_night.svg", width=128, height=128, alt="")
</template>

<style lang="stylus" scoped>
  .map {
    background: #eeeeee;
    width: 100%;
    height: 100%;
  }

  .jiko
    position: relative

    &-time
      display: flex
      flex-flow: row nowrap
      position: absolute
      top: 10px
      right: 10px

  .btn
    padding: 10px
    width: 50px
    height: @width
    border-radius: 50%
    margin: 0 8px
    background: #ffffff
    /*border: 2px solid #cccccc*/
    box-shadow: 2px 2px 4px #666

  .morning
    background: #d8741f
  .evening
    background: #38aa62
  .night
    background: #1f2d66
</style>

<script>
  import axios from 'axios'
  import Feature from 'ol/Feature'
  import Point from 'ol/geom/Point'
  import { Tile as TileLayer } from 'ol/layer'
  import Heatmap from 'ol/layer/Heatmap'
  import Map from 'ol/Map'
  import { transform } from 'ol/proj'
  import VectorSource from 'ol/source/Vector'
  import XYZ from 'ol/source/XYZ'
  import { Icon, Style } from 'ol/style'
  import View from 'ol/View'
  import animejs from 'animejs'

  export default {
  metaInfo: {
    title: "事故状況"
  },
  data() {
    return {
      morning: {},
      evening: {},
      night: {},
      animation: null
    }
  },
  computed: {},
  created() {
    this.$nextTick(() => {
      // this.mapping(null, null);
      this.position()
    });
  },
  methods: {
    async position () {
      const res = await axios.get(
        'https://terasumunet.azurewebsites.net/api/HighTrafficRoad?code=oKhdlqjbxiyWfptTmYiq3cS3ozhqs%2FFS1passqrRaE%2FTqu9E16AjEQ%3D%3D&CITYCODE&fbclid=IwAR3VNS5umsjsw4uVkACBbg2m-PbAk1p-TQSpmnDoF30pxHSopG3qJ-r8Vlg',
        { params: {} },
        { timeout: 30000 }
      )

      this.mapping(res.data)
    },

    mapping (data) {
      const pos = transform(
        [130.912715, 33.834125],
        "EPSG:4326",
        "EPSG:3857"
      );

      const morning = []
      const evening = []
      const night = []

      for (let i = 0, iLen = data.length; i < iLen; i++) {
        const item = data[i]

        const pointer = transform([item.LONGITUDE, item.LATITUDE], 'EPSG:4326', 'EPSG:3857')

        const marker = new Feature({
          geometry: new Point(pointer)
        });

        switch (item.TYUUYA) {
          case '朝':
            morning.push(marker)
            break
          case '昼':
            evening.push(marker)
            break
          case '夜':
            night.push(marker)
            break
        }
      }

      this.morning = new Heatmap({
        source: new VectorSource({
          features: morning
        }),
        blur: 10,
        visible: true
      })

      this.evening = new Heatmap({
        source: new VectorSource({
          features: evening
        }),
        blur: 10,
        visible: false
      })

      this.night = new Heatmap({
        source: new VectorSource({
          features: night
        }),
        blur: 10,
        visible: false
      })

      const baseLayer = new TileLayer({
        source: new XYZ({
          url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        })
      })

      const map = new Map({
        target: "map",
        pixelRatio: 2,
        layers: [
          baseLayer,
          this.morning,
          this.evening,
          this.night
        ],
        view: new View({
          center: pos,
          zoom: 13
        })
      });

      map.once("postrender", () => {
      });
    },

    switchHeatMap (type) {
      switch (type) {
        case 0:
          // 朝
          this.morning.setVisible(true)
          this.evening.setVisible(false)
          this.night.setVisible(false)
          break
        case 1:
          // 昼
          this.morning.setVisible(false)
          this.evening.setVisible(true)
          this.night.setVisible(false)
          break
        case 2:
          // 夜
          this.morning.setVisible(false)
          this.evening.setVisible(false)
          this.night.setVisible(true)
          break
        default:
          break
      }
    },

    scaleOut (event) {
      if (/active/.test(event.target.className)) return false

      this.animation = animejs({
        targets: event.target,
        scale: 0.8,
        duration: 400,
        complete: () => {
          this.animation = null
        }
      })
    },

    scaleIn (event) {
      if (/active/.test(event.target.className)) return false
      if (this.animation) this.animation.pause()

      this.animation = animejs({
        targets: event.target,
        scale: [0.8, 1],
        duration: 300,
        complete: () => {
          this.animation = null
        }
      })
    }
  }
};
</script>