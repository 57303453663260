<template lang="pug">
  small.copy &copy; MaaS
</template>

<style lang="stylus" scoped>
  .copy
    text-align: center
    font-size: 1.6rem
    letter-spacing: 1.5px
</style>

<script>
  export default {}
</script>