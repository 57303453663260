<template lang="pug">
  .sub
    #map.map
</template>

<style lang="stylus" scoped>
.map {
    background: #eeeeee;
    width: 100%;
    height: 100%;
}
</style>

<script>
import Feature from "ol/Feature";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import Map from "ol/Map";
import { transform } from "ol/proj";
import XYZ from "ol/source/XYZ";
import View from "ol/View";
import { Icon, Style } from "ol/style";
import markerimg from 'img/light_on.png'
import markerimgOff from 'img/dummy_pin_2.png'
import VectorSource from "ol/source/Vector";
import Point from "ol/geom/Point";
import { asString as ol_color_asString } from 'ol/color'
import axios from 'axios'

export default {
  metaInfo: {
    title: "街灯設置状況"
  },
  data() {
    return {
      pointer: []
    }
  },
  computed: {},
  created() {
    this.$nextTick(() => {
      this.position()
    });
  },
  methods: {
    async position () {
      const res = await axios.get(
        'https://terasumunet.azurewebsites.net/api/StreetLights?code=YR2A7MmCHyhMeQez9dhh4NzN2VCIf2T272SY60cReBL72tG0hTQaKA%3D%3D&CITYCODE=22&fbclid=IwAR2gwen0bs9KN8teMr6uTaTvDYaF8Vopx5iVQuerILkX-K_8iqjqrQ0HZzY',
        { params: {} },
        { timeout: 30000 }
      )

      // console.log(data)
      this.mapping(res.data)
    },

    mapping (data) {
      const pos = transform(
        [138.254338, 34.776668],
        "EPSG:4326",
        "EPSG:3857"
      );

      const arry = [];

      for (let i = 0, iLen = data.length; i < iLen; i++) {
        const item = data[i]

        // ライトがついてない場合は処理しない
        if (item.OnOff !== 1) continue

        const pointer = transform([item.Longitude, item.Latitude], 'EPSG:4326', 'EPSG:3857')
        this.pointer.push(pointer)

        const marker = new Feature({
          geometry: new Point(pointer)
        });

        // "GAIRONO": "H31.2.11",

        marker.setStyle(
          new Style({
            image: new Icon({
                // src: item.GAIRONO === 'H73.1.5' ? markerimgOff : markerimg,
                src: markerimg,
                opacity: 0.8
              })
          })
        );
        arry.push(marker);
      }

      const baseLayer = new TileLayer({
        source: new XYZ({
          url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        })
      })

      const map = new Map({
        target: "map",
        pixelRatio: 2,
        layers: [
          baseLayer,
          new VectorLayer({
            source: new VectorSource({
              features: arry
            })
          })
        ],
        view: new View({
          center: pos,
          zoom: 13
        })
      });

      baseLayer.on('postcompose', (event) => {
        const ctx = event.context
        const ratio = event.frameState.pixelRatio
        const w = ctx.canvas.width
        const h = ctx.canvas.height
        ctx.save()
        ctx.scale(ratio, ratio)

        ctx.fillStyle = ol_color_asString([0, 0, 0, 0.6])
        ctx.fillRect(0, 0, w, h)

        ctx.restore()
      })

      map.once("postrender", () => {
      });
    }
  }
};
</script>