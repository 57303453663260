var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "home-logo" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "home-center home-logo-img" }, [
        _c(
          "svg",
          {
            staticClass: "logos",
            attrs: {
              id: "logos",
              height: "40",
              viewBox: "0 0 264 40",
              width: "264",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
              _c("g", { staticClass: "logos-g" }, [
                _c("path", {
                  attrs: {
                    d:
                      "m.56 1.6c10.7733872 0 21.7332776-.266664 32.88-.8l.2 4.8c-6.3733652.533336-11.1866504 1.933322-14.44 4.2s-4.88 5.0266504-4.88 8.28c0 2.66668.9066576 4.7733256 2.72 6.32s4.1999852 2.32 7.16 2.32c1.5466744 0 3.1733248-.1599984 4.88-.48l.6 4.88c-2.00001.3733352-3.9199908.56-5.76.56-4.66669 0-8.3866528-1.1866548-11.16-3.56s-4.16-5.5466468-4.16-9.52c0-2.4533456.7599924-4.7733224 2.28-6.96s3.679986-4.0266592 6.48-5.52v-.08c-5.8400292.2400012-11.4399732.36-16.8.36z",
                    fill: "#ff5d41",
                    transform: "translate(0 3)"
                  }
                })
              ]),
              _vm._v(" "),
              _c("g", { staticClass: "logos-g" }, [
                _c("path", {
                  attrs: {
                    d:
                      "m2.44000004 8.2 5.2.4c-.2400012 2.00001-.6133308 4.5466512-1.12 7.64l.08.04c1.7600088-1.2266728 3.71998916-2.1933298 5.87999996-2.9s4.1866572-1.06 6.08-1.06c3.9733532 0 6.8999906.7799922 8.78 2.34s2.82 3.939984 2.82 7.14c0 3.5466844-1.4933184 6.2999902-4.48 8.26s-7.3199716 2.94-13 2.94c-3.12001556 0-6.18665156-.1733316-9.19999996-.52l.36-4.6c3.8933528.400002 6.83998996.6 8.83999996.6 3.6800184 0 6.5133234-.5933274 8.5-1.78s2.98-2.8199896 2.98-4.9c0-3.2800164-2.133312-4.92-6.4-4.92-3.5200176 0-7.7066424 1.6266504-12.55999996 4.88l-4.76-1.04c.800004-4.533356 1.466664-8.7066476 2-12.52zm3.6-7.6c6.29336476.800004 12.63996796 1.2 19.03999996 1.2v4.52c-6.5866996 0-13.1199676-.399996-19.59999996-1.2z",
                    fill: "#5da84b",
                    transform: "translate(49 6)"
                  }
                })
              ]),
              _vm._v(" "),
              _c("g", { staticClass: "logos-g" }, [
                _c("path", {
                  attrs: {
                    d:
                      "m.60000008 4.72h18.92000002v-4.4h5.08v4.4h10.64v4.28h-10.64v7.96c.6933368 1.5200076 1.04 3.3066564 1.04 5.36 0 4.0533536-1.1599884 7.1199896-3.48 9.2s-6.1733064 3.4133304-11.56 4l-.80000002-4.64c3.81335242-.4266688 6.45999262-1.073329 7.94000002-1.94s2.4066648-2.166658 2.78-3.9c0-.0266668.0066666-.0599998.02-.1s.02-.0733332.02-.1l-.04-.04c-1.3866736 1.1200056-3.146656 1.68-5.28 1.68-2.4266788 0-4.406659-.7399926-5.94000002-2.22-1.533341-1.4800074-2.3-3.3666552-2.3-5.66 0-2.3200116.7799922-4.213326 2.34-5.68 1.56000782-1.466674 3.57998762-2.2 6.06000002-2.2 1.3866736 0 2.7333268.3199968 4.04.96h.08v-2.68h-18.92000002zm12.56000002 11.32c-.7466704.66667-1.12 1.5199948-1.12 2.56s.3733296 1.8999966 1.12 2.58 1.7066608 1.02 2.88 1.02 2.1333296-.3399966 2.88-1.02 1.12-1.5399948 1.12-2.58-.3733296-1.8999966-1.12-2.58-1.7066608-1.02-2.88-1.02-2.1333296.3466632-2.88 1.04z",
                    fill: "#b4ae25",
                    transform: "translate(91)"
                  }
                })
              ]),
              _vm._v(" "),
              _c("g", { staticClass: "logos-g" }, [
                _c("path", {
                  attrs: {
                    d:
                      "m4.64000011 34.12-.84-4.8c4.266688-.4266688 7.81998579-1.173328 10.65999999-2.24s5.0999916-2.5599904 6.78-4.48c-3.1733492-1.6266748-6.8666456-3.3866572-11.08-5.28l2.52-4.64c4.266688 1.9200096 8.0266504 3.7066584 11.28 5.36.9600048-2.4800124 1.5466656-5.5599816 1.76-9.24h-13.96c-1.6266748 4.3466884-4.23998199 8.2399828-7.83999999 11.68l-3.68-3.24c2.266678-2.2400112 4.133326-4.8466518 5.6-7.82s2.3866648-6.0333176 2.76-9.18l5.07999999.32c-.133334 1.3066732-.3066656 2.4533284-.52 3.44h18.24v1.72c0 9.3067132-2.1133122 16.1733112-6.34 20.6s-11.0332864 7.0266628-20.41999999 7.8z",
                    fill: "#0c2586",
                    transform: "translate(137 5)"
                  }
                })
              ]),
              _vm._v(" "),
              _c("g", { staticClass: "logos-g" }, [
                _c("path", {
                  attrs: {
                    d:
                      "m.36000015 6.72h12.56000005v-6h5.32v6h13.08v7.48c0 6.3200316-1.9599804 11.2199826-5.88 14.7s-9.7332948 5.419998-17.44000005 5.82l-.72-4.8c6.42669885-.3466684 11.14665165-1.8066538 14.16000005-4.38s4.52-6.3533084 4.52-11.34v-2.8h-20.40000005v8.8h-5.2z",
                    fill: "#ba1bbe",
                    transform: "translate(183 1)"
                  }
                })
              ]),
              _vm._v(" "),
              _c("g", { staticClass: "logos-g" }, [
                _c("path", {
                  attrs: {
                    d:
                      "m.92000019 4.8 2.56-4.8c4.0266868 1.9200096 8.15997881 4.0666548 12.40000001 6.44l-2.68 4.72c-3.57335121-2.00001-7.66664361-4.1199888-12.28000001-6.36zm27.00000001-.96 5.08 1.12c-1.4133404 7.9200396-4.6133084 14.013312-9.6 18.28s-11.9199556 6.7866628-20.80000001 7.56l-.8-5.2c7.733372-.7466704 13.67331261-2.8599826 17.82000001-6.34s6.9133264-8.619966 8.3-15.42z",
                    fill: "#000",
                    transform: "translate(231 7)"
                  }
                })
              ])
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "home-nav", attrs: { id: "home-nav" } }, [
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "gaitou" } }, [
            _c("img", {
              attrs: {
                src: require("../../img/home_gaito.png"),
                width: "90",
                height: "115",
                alt: ""
              }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "jiko" } }, [
            _c("img", {
              attrs: {
                src: require("../../img/home_jiko.png"),
                width: "90",
                height: "115",
                alt: ""
              }
            })
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "home-center home-icon" }, [
      _c("img", {
        staticClass: "home-img",
        attrs: {
          id: "home-img",
          src: require("../../img/logo_img.png"),
          width: "75",
          height: "100",
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }