var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "nav" }, [
    _c("ul", { staticClass: "list" }, [
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "home" } }, [
            _c("img", {
              attrs: {
                src: require("../../img/menu_home.png"),
                alt: "",
                width: "60",
                height: "40"
              }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "gaitou" } }, [
            _c("img", {
              attrs: {
                src: require("../../img/menu_gaito.png"),
                alt: "",
                width: "60",
                height: "40"
              }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "jiko" } }, [
            _c("img", {
              attrs: {
                src: require("../../img/menu_jiko.png"),
                alt: "",
                width: "60",
                height: "40"
              }
            })
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }