var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "jiko" }, [
    _c("div", { staticClass: "map", attrs: { id: "map" } }),
    _vm._v(" "),
    _c("div", { staticClass: "jiko-time" }, [
      _c(
        "button",
        {
          staticClass: "btn morning",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              _vm.switchHeatMap(0)
            },
            touchstart: function($event) {
              $event.stopPropagation()
              _vm.scaleOut($event)
            },
            touchend: function($event) {
              $event.stopPropagation()
              _vm.scaleIn($event)
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("../../img/icon_morning.svg"),
              width: "128",
              height: "128",
              alt: ""
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn evening",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              _vm.switchHeatMap(1)
            },
            touchstart: function($event) {
              $event.stopPropagation()
              _vm.scaleOut($event)
            },
            touchend: function($event) {
              $event.stopPropagation()
              _vm.scaleIn($event)
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("../../img/icon_evening.svg"),
              width: "128",
              height: "128",
              alt: ""
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn night",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              _vm.switchHeatMap(2)
            },
            touchstart: function($event) {
              $event.stopPropagation()
              _vm.scaleOut($event)
            },
            touchend: function($event) {
              $event.stopPropagation()
              _vm.scaleIn($event)
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("../../img/icon_night.svg"),
              width: "128",
              height: "128",
              alt: ""
            }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }